const WOW = require("wow.js");

window.wow = new WOW({
  live: false,
});

window.wow.init();

window.openModal = function (event) {
  var target = document.getElementById(event.target.dataset.target);
  target.classList.add("modal--open");
};

window.closeModal = function (event) {
  var modals = Array.from(document.getElementsByClassName("modal"));
  modals.map((modal) => modal.classList.remove("modal--open"));
};

document.addEventListener("DOMContentLoaded", function () {
  var elements = document.getElementsByTagName("input");
  for (var i = 0; i < elements.length; i++) {
    elements[i].oninvalid = function (e) {
      e.target.setCustomValidity("");
      if (!e.target.validity.valid) {
        e.target.classList.add("form__error");

        if (e.target.name === "email") {
          e.target.setCustomValidity(
            "Bitte eine richtige E-Mail-Adresse angeben"
          );
        } else {
          e.target.setCustomValidity("Bitte füllen Sie auch dieses Feld aus.");
        }
      }
    };
    elements[i].oninput = function (e) {
      e.target.classList.remove("form__error");
      e.target.setCustomValidity("");
    };
  }

  var not_interested = document.getElementById("not_interested");
  not_interested.addEventListener("click", function (event) {
    var cb = document.getElementById(event.target.attributes["for"].value);
    if (!cb.checked) {
      console.log(cb.parentElement.children);
      Array.from(cb.parentElement.children).forEach((element) => {
        if (element.tagName === "INPUT") {
          element.checked = false;
        }
      });
    }
  });

  document.getElementById("form").addEventListener("submit", function (event) {
    event.preventDefault();
    event.stopPropagation();
    var form = document.getElementById("form");
    var formData = new FormData(form);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "mail.php", true);
    xhr.onload = function () {
      if (xhr.status === 200) {
        var success = document.getElementById("success");
        success.classList.add("form__success--visible");
        form.reset();
        window.scrollTo(0, success.offsetTop - "20");
        window.setTimeout(() => {
          success.classList.remove("form__success--visible");
        }, 5000);
      } else {
        alert("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
      }
    };
    xhr.formData = formData;
    xhr.send(formData);
  });
});
